.byTypeStyle {
  padding-top: 2vh;
  width: 100%;
  padding-left: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #faf7f7;
  height: auto;
}
.cardGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  margin: 32px 0;
  background-color: #faf7f7;
}

.cardSizeCat {
  max-height: 300px;
  justify-content: center;
  margin: 40px 32px;
}
.cardSizeCat a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 8px;
}
.cardSizeCat a:hover {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid #808080;
}
.imageCat {
  height: 300px;
  align-self: center;
}
.cardSizeType {
  max-width: 500px;
  max-height: 500px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-self: space-around;
  margin-bottom: 64px;
}
.cardSizeType a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 8px;
  border: none;
  max-width: 100%;
  max-height: 100%;
}
.cardSizeType a:hover {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid #808080;
}
.imageType {
  max-width: 100%;
  max-height: 100%;
  align-self: center;
}

/*Laptop*/
@media screen and (min-width: 1400px) and (max-width: 1900px) {
  .cardSizeCat {
    height: 300px;
  }
  .imageCat {
    height: 250px;
  }
}

@media screen and (max-width: 1400px) {
  .cardSizeCat {
    height: 250px;
    margin:0;
  }
  .imageCat {
    height: 250px;
  }
}

/* Tablette */
@media screen and (min-width: 771px) and (max-width: 1050px) {
  .cardSizeCat {
    height: 150px;
    margin: 0 16px;
  }
  .imageCat {
    height: 150px;
  }
}


@media screen and (max-width: 770px) {
  .byTypeStyle {
    padding-left: 250px;
    height: auto;
    justify-content: flex-start;
  }
  .cardSizeCat {
    width: 100%;
    height: 150px;
    margin: 0;
  }
  .cardSizeCat a {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    height: 150px;
    border: 1px solid #808080;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  .cardSizeCat a:hover {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 150px;
  }
  .imageCat {
    height: 100px;
  }
}
/*Mobile*/
@media screen and (max-width: 769px) {
  .byTypeStyle {
    padding-left: 200px;
    height: auto;
    justify-content: flex-start;
  }
  .cardSizeCat {
    height: 100px;
    margin-bottom: 8px;
  }
  .cardSizeCat a {
    width: 80%;
  }
  .cardSizeCat a:hover {
    height: 100px;
  }
  .imageCat {
    height: 80px;
  }
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  .byTypeStyle {
    padding-top:120px;
    padding-left: 0px;
    height: auto;
  }
  .cardGroup {
    flex-direction: column;
  }
  .cardSizeCat {
    height: 80px;
    margin-bottom: 8px;
  }
  .cardSizeCat a {
    width: 100%;
    justify-content: space-around;
  }
  .cardSizeCat a:hover {
    height: 80px;
  }
  .imageCat {
    height: 60px;
  }
}