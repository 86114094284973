div.eventPage {
  padding-top: 2vh;
  width: 100%;
  padding-left: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #faf7f7;
}
.headerTitle {
  max-width: 800px;
}
.headerCard {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.imageEvent {
  position: absolute;
  right: 20px;
  max-width: 170px;
  max-height: 170px;
}

/*Laptop*/
@media screen and (min-width: 1400px) and (max-width: 1900px) {
  .imageEvent {
    max-width: 120px;
    max-height: 100px;
  }
}

@media screen and (max-width: 1400px) {
  .imageEvent {
    max-width: 100px;
    max-height: 100px;
  }
}

/* Tablette */
@media screen and (min-width: 770px) and (max-width: 1050px) {
  .imageEvent {
    max-width: 80px;
    max-height: 80px;
  }
}

@media screen and (max-width: 780px) {
  div.eventPage {
    padding-left: 250px;
  }
  .imageEvent {
    position: absolute;
    right: 8px;
    max-width: 60px;
    max-height: 70px;
  }
  .headerCard {
    max-height: 45px;
  }
}


/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 400px) {

}
@media screen and (min-width: 350px) and (max-width: 420px) {
  div.eventPage {
    padding-left: 0px;
    padding-top: 120px;
  }
  .imageEvent {
    position: absolute;
    right: 8px;
    max-width: 60px;
    max-height: 60px;
  }
  .headerCard {
    max-height: 45px;
  }
}