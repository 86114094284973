/* desktop */
.homePageSection {
  position: relative;
  background: linear-gradient(180deg, #faf7f7 60vh, #808080 60vh);
  padding-left: 400px;
  height: 100vh;
}

div.firstRow {
  height: 60vh;
}
.imgPres {
  padding-top: 64px;
  width: 100%;
  height: auto;
}

div.secondRow {
  height: 40vh;
}
.imageText {
  padding: 0;
  justify-content: center;
}
.eventsInComingSection {
  position: relative;
  background: #faf7fa;
  padding-left: 400px;
}

/*Laptop*/
@media screen and (min-width: 1400px) and (max-width: 1900px) {
}


/* Tablette */
@media screen and (min-width: 771px) and (max-width: 1399px) {
}

@media screen and (max-width: 770px) {
  .homePageSection {
    padding-left: 250px;
    background: linear-gradient(180deg, #faf7f7 68vh, #808080 68vh);
  }
  .imgPresMobile{
    display: flex;
    align-items: center;
    justify-content: center;
   }
   .imgPres {
     width: 100%;
   }
  div.firstRow {
    height: 65vh;
  }
  div.secondRow {
    height: 35vh;
  }
  .eventsInComingSection {
    padding-left: 250px;
  }
}

/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 400px) {
  .homePageSection {
    padding-left: 200px;
    background: linear-gradient(180deg, #faf7f7 55vh, #808080 55vh);
  }
  div.firstRow {
    height: 65vh;
  }
  .imgPresMobile {
    max-height: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .imgPres {
    padding-top: 8px;
    max-width: 100px;
  }
  div.secondRow {
    height: 20vh;
  }
  .firstParagraphMobile {
    position: absolute !important;
    width: 300px !important;
    right: 10px;
    padding: 0;
  }
  .imageText {
    position: absolute !important;
    width: 400px !important;
    right: 50px;
    bottom: 10px;
    padding: 0;
  }
  .eventsInComingSection {
    padding-left: 250px;
  }
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  .homePageSection {
    height: auto;
    padding-left: 0px;
    padding-top: 120px;
    background: linear-gradient(180deg, #faf7f7 70vh, #808080 70vh);
  }
  div.firstRow {
    height: 52vh;
  }
  .imgPresMobile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .imgPres {
    padding-top: 0px;
    max-width: 60%;
    margin-right: auto;
    margin-left: auto;
  }
  div.secondRow {
    height: 35vh;
  }
  .firstParagraphMobile {
    padding: 0;
  }
  .imageText {
    padding: 0;
  }
  .eventsInComingSection {
    padding-left: 0px;
  }
}
