.bySubCatStyle {
  padding-top: 2vh;
  width: 100%;
  padding-left: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #faf7f7;
  height: auto;
}
.cardSizeSubCat {
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  margin-bottom: 64px;
}
.cardSizeSubCat a {
  max-width: 500px;
  max-height: 400px;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  justify-content: center;
  align-self: center;
  padding: 8px;
  border: none;
}
.cardSizSubCat a:hover {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid #808080;
}
.imageSubCat {
  max-width: 100%;
  max-height: 100%;
  align-self: center;
}

/*Laptop*/
@media screen and (min-width: 1400px) and (max-width: 1900px) {
}

@media screen and (max-width: 1400px) {

}

/* Tablette */
@media screen and (min-width: 771px) and (max-width: 1050px) {

}

@media screen and (max-width: 770px) {
  .bySubCatStyle {
    padding-left: 250px;
    height: auto;
    justify-content: flex-start;
  }
}

/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 400px) {
  .bySubCatStyle {
    padding-left: 200px;
    height: auto;
  }
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  .bySubCatStyle {
    padding-top: 120px;
    padding-left: 0px;
    height: auto;
  }
  .cardGroup {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
  }
}