/* import fonts */
@font-face {
  font-family: 'Dolce Vita Light';
  src: url('./components/fonts/dolce_vita/Dolce_Vita_Light.ttf');
}

@font-face {
  font-family: 'Dolce Vita';
  src: url('./components/fonts/dolce_vita/Dolce_Vita.ttf');
}

@font-face {
  font-family: 'Bahnschrift';
  src: url('./components/fonts/bahnschrift/Bahnschrift.ttf');
}

@font-face {
  font-family: 'Quicksand';
  src: url('./components/fonts/quicksand/Quicksand-Regular.ttf');
}

@font-face {
  font-family: 'Quicksand Bold';
  src: url('./components/fonts/quicksand/Quicksand-Bold.ttf');
}

/* global */
body {
  background-color: #faf7f7 !important;
  height: 100vh;
}
.App h1,
h2 {
  font-family: 'Dolce Vita Light';
}
.App h1 {
  font-size: 96px;
  margin-top: 64px;
  margin-bottom: 64px;
}
h1.first-title {
  font-size: 120px;
  text-align: center;
  margin-top: 96px;
  margin-bottom: 32px;
}
h1.logo {
  margin-top: 0px;
  margin-bottom: 8px;
  color: black;
}

h1.title-centered-white {
  text-align: center;
  color: #faf7f7;
}
h1.title-centered {
  text-align: center;
}
h1.title-centered-no-margin-bottom {
  text-align: center;
  margin-bottom: 0;
  letter-spacing: 15px;
}
h1.title-centered-no-margin-top {
  text-align: center;
  margin-top: 0;
}
h2.title-centered {
  text-align: center;
  font-size: 44px;
}

.App h3 {
  font-family: 'Dolce Vita';
  color: #faf7f7;
  font-size: 40px;
  padding: 8px;
}
h3.titleEventComing {
  padding: 20px;
}
h3.title-black {
  color: black;
}
h3.title-black-centered {
  color: black;
  text-align: center;
}
h3.title-art {
  font-size: 46px;
}
h3.title-centered {
  font-family: 'Dolce Vita Light';
  color: black;
  text-decoration: underline;
  font-size: 30px;
}
.App h4 {
  font-family: 'Dolce Vita Light';
  color: #faf7f7;
  font-size: 44px;
  padding: 8px;
}
h4.type {
  font-size: 44px;
  margin-top: 64px;
}
h4.title-centered {
  color: black;
  text-align: center;
  margin-top: 64px;
}
.App button.seeMore {
  font-family: 'Bahnschrift';
}

.App p {
  font-size: 15px;
  font-family: 'Quicksand', sans-serif;
  text-transform: uppercase;
}

.App b {
  font-size: 15px;
  font-family: 'Quicksand bold', sans-serif;
  text-transform: uppercase;
}
input.input-mail-top {
  height: 60px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-radius: unset;
  font-family: 'Quicksand';
  text-transform: capitalize;
  padding: 16px;
  background-color: transparent;
}
input.input-mail-top-left {
  height: 60px;
  border-top: 1px solid black;
  border-left: 1px solid black;
  border-right: unset;
  border-radius: unset;
  font-family: 'Quicksand';
  text-transform: capitalize;
  padding: 16px;
  background-color: transparent;
}
input.input-mail-center {
  height: 60px;
  border: 1px solid black;
  border-radius: unset;
  font-family: 'Quicksand';
  text-transform: capitalize;
  padding: 16px;
  background-color: transparent;
}
input.input-mail-center-top {
  height: 60px;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-top: 1px solid black;
  border-bottom: unset;
  border-radius: unset;
  font-family: 'Quicksand';
  text-transform: capitalize;
  padding: 16px;
  background-color: transparent;
}
textarea.form-control {
  height: 200px !important;
  border-top: unset;
  border-left: 1px solid black;
  border-right: 1px solid black;
  border-bottom: 1px solid black;
  border-radius: unset;
  font-family: 'Quicksand';
  text-transform: capitalize;
  padding: 16px;
  background-color: transparent;
}
div.row {
  margin: 0;
}
div.row-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
}
div.row-flex-between {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
div.row-flex-left {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}
div.row-flex-spaced {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-left: 32px;
  padding-right: 32px;
}
div.col-flex {
  display: flex;
  flex-direction: column;
}
div.col-flex-centered {
  display: flex;
  flex-direction: column;
  align-self: center;
}
div.col,
div.col-lg-4,
.col-lg-4,
.col-md-4 {
  padding: 0;
}
.margin-bot {
  margin-bottom: 32px;
}
div.margin-up {
  margin-top: 64px;
  display: flex;
  justify-content: center;
}
div.box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 250px;
  max-height: 100%;
  border: 1px solid #faf7f7;
  padding: 16px;
}
/* Events In coming */

div.card-event-coming {
  width: 100%;
  background-color: #808080;
  margin: 0;
  border-radius: 0;
  border: none;
  margin-bottom: 16px;
}
div.card-body-event-coming {
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
p.text-event-coming {
  font-size: 22px;
  line-height: 0.8;
  color: #faf7f7;
  padding: 0 8px;
}
.react-html-parser p {
  max-width: 70%;
  padding: 16px 8px;
  font-size: 18px;
  line-height: 1.5;
  color: #faf7f7;
  letter-spacing: 0.2rem;
}
div.cta {
  background-color: #808080;
  display: flex;
  justify-content: center;
  margin-top: -16px;
  padding-bottom: 32px;
  padding-top: 32px;
}
a.event-link {
  border: 1px solid #faf7f7;
  padding: 8px;
  font-family: 'Bahnschrift';
  text-transform: uppercase;
  background-color: transparent;
  text-decoration: none;
  color: #faf7f7;
}
a.event-link:hover {
  border: 1px solid #faf7f7;
  background-color: #faf7f7;
  color: #808080;
  text-decoration: none;
}
div.cta-see-more {
  display: flex;
  justify-content: center;
  max-height: 50px;
}
a.see-more {
  background-color: #faf7f7;
  border: 1px solid black;
  border-radius: 0;
  font-family: 'Bahnschrift';
  text-transform: uppercase;
  font-weight: 600;
  color: black;
  font-size: 16px;
  text-decoration: none;
  padding: 8px;
}
a.see-more:hover {
  background-color: #808080;
  color: #faf7f7;
  font-size: 16px;
  text-decoration: none;
}

/* Events */
div.card-event {
  width: 70%;
  margin: 0;
  border-radius: 0;
  border: 1px solid black;
  margin-bottom: 96px;
  align-self: center;
  height: 350px;
}
div.card-body-event {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0;
}
p.text-event {
  font-size: 22px;
  line-height: 1.2;
  color: black;
  padding: 0;
}
p.text-event p {
  font-size: 18px;
  line-height: 1.4;
  color: black;
  padding: 0;
  margin-top: 16px;
}

div.card-event-past {
  background-color: #faf7f7;
  min-width: 50%;
  max-width: 50%;
  padding: 32px;
  margin: 0;
  border-radius: 0;
  border: none;
  margin-bottom: 96px;
  height: 1000px;
}
div.card-body-event-past {
  background-color: #faf7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
div.event-img {
  max-width: 350px;
  height: 200px;
  padding: 0px 16px 16px 16px;
}
div.event-img img {
  max-height: 100%;
  max-width: 100%;
}
div.card-title-event-past {
  height: 100px;
  text-align: center;
}
div.card-body-height {
  height: 600px;
}
p.text-event-past {
  height: 100px;
  display: flex;
  justify-content: center;
  text-align: center;
}
p.text-event-past p {
  font-size: 18px;
  line-height: 1.4;
  color: black;
  padding: 0;
  margin-top: 16px;
}

div.text-single-event {
  display: flex;
  justify-content: center;
  margin-top: 8px;
  padding-left: 170px;
  height: 100px;
}
div.text-single-event p {
  width: 100%;
  align-self: center;
  font-family: 'Quicksand';
  text-transform: unset;
  text-align: center;
  font-size: 22px;
  line-height: 1.4;
  color: #faf7f7;
  padding: 0;
}

.image-gallery {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
}
.image-gallery-content.left .image-gallery-slide .image-gallery-image {
  width: 65vh !important;
  height: 50vh !important;
}

/* Arts */

div.text-single-art {
  display: flex;
  justify-content: center;
  text-transform: uppercase;
  line-height: 1.4;
  padding: 0px;
}
div.text-single-art p {
  font-family: 'Quicksand';
  text-transform: unset;
  text-transform: uppercase;
  text-align: left;
  font-size: 18px;
  line-height: 1.4;
  color: #faf7f7;
  padding: 0;
  margin: 0;
}
div.text-single-art b {
  font-family: 'Quicksand Bold';
  text-transform: unset;
  text-transform: uppercase;
  line-height: 1.4;
  color: #faf7f7;
  margin-top: 16px;
}
p.margin-0 {
  margin: 0;
}
div.card-galery {
  height: 320px;
  max-width: 100%;
  margin: 0 32px;
  border-radius: 0;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #808080;
}
.card-galery a {
  height: 320px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: none;
  background-color: #808080;
}
.card-galery a:hover {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid black;
}
.card-galery h3 {
  max-width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: center;
  z-index: 1;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #faf7f7;
  top: 45%;
}
.card-galery img {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
  opacity: 0.7;
}
div.card-galery-vertical {
  height: 320px;
  width: 225px;
  margin: 0 32px;
  border-radius: 0;
  border: none;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #808080;
}
.card-galery-vertical a {
  height: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: none;
  background-color: #808080;
}
.card-galery-vertical a:hover {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid black;
}
.card-galery-vertical h3 {
  max-width: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-self: center;
  z-index: 1;
  text-align: center;
  padding: 0;
  margin: 0;
  color: #faf7f7;
  top: 45%;
}
.card-galery-vertical img {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  z-index: 0;
  opacity: 0.7;
}

div.card-art {
  min-width: 33%;
  max-width: 33%;
  height: 280px;
  border-radius: 0;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px;
  margin-bottom: 64px !important;
  background-color: #faf7f7;
}
.card-art a {
  max-width: 350px;
  height: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: none;
}
.card-art a:hover {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid #808080;
}
.card-art img {
  max-width: 100%;
  max-height: 100%;
}
/* Contact */
p.contact-text {
  font-family: 'Quicksand';
  font-size: 20px;
  text-transform: uppercase;
  text-align: left;
  padding: 0;
  margin: 0;
}
a.mail {
  font-family: 'Quicksand';
  font-size: 18px;
  text-transform: uppercase;
  text-decoration: none;
  color: black;
  padding-left: 8px;
}
a.mail:hover {
  font-family: 'Quicksand';
  font-size: 18px;
  text-transform: uppercase;
  color: #808080;
}
button.submit-button {
  margin-top: 32px;
  border: 1px solid black;
  font-family: 'Bahnschrift';
  padding: 8px 16px;
  text-transform: uppercase;
  border-radius: unset;
  font-size: 14px;
  background-color: #faf7f7;
  color: black;
}
button.submit-button:hover {
  margin-top: 32px;
  border: 1px solid black;
  font-family: 'Bahnschrift';
  padding: 8px 16px;
  text-transform: uppercase;
  border-radius: unset;
  font-size: 14px;
  background-color: #808080;
  color: #faf7f7;
}
/* Navbar */
.dropdown-menu.show {
  border: none;
  position: relative !important;
  transform: unset !important;
  width: 200px;
  background-color: transparent;
}
.Navbar_navItem li {
  position: relative;
}

/* footer */
p.footerText {
  font-size: 9px;
  margin: 0;
}

a.link {
  color: black;
  text-decoration: none;
  font-family: 'Quicksand';
  font-size: 9px;
  text-transform: uppercase;
  padding-left: 8px;
}
a.link:hover {
  color: #808080;
}
a.link:visited {
  color: black;
}
a.link:visited:hover {
  color: #808080;
}

/* error */

p.error-text {
  font-size: 20px;
  text-align: center;
  margin-top: 64px;
}
a.back {
  width: 200px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  align-content: center;
  background-color: #faf7f7;
  color: black;
  font-family: 'Dolce Vita';
  border: 1px solid black;
  padding: 8px;
  margin-top: 64px;
}
a.back:hover {
  text-decoration: none;
  background-color: #808080;
  color: #faf7f7;
}
p.p-link {
  margin: 0;
}
span.pic {
  display: inline-block;
  vertical-align: middle;
  width: 0;
  height: 0;
}
span.arrow-left {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-right: 6px solid #808080;
  margin-left: 8px;
}
span.arrow-right {
  border-top: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid #808080;
  margin-right: 8px;
}

/*Laptop*/
@media screen and (min-width: 1400px) and (max-width: 1900px) {
  .App h1 {
    font-size: 64px;
  }
  h1.first-title {
    font-size: 96px;
  }
  .App h3 {
    font-size: 30px;
  }
  h3.title-black {
    font-size: 25px;
  }
  h3.title-art {
    font-size: 40px;
  }
  h3.title-centered {
    font-size: 30px;
  }
  .App h4 {
    font-size: 40px;
  }
  h4.type {
    font-size: 50px;
  }
  .App p {
    font-size: 13px;
  }
  .App b {
    font-size: 13px;
  }
  input.input-mail-top {
    height: 50px;
  }
  input.input-mail-top-left {
    height: 50px;
  }
  input.input-mail-center {
    height: 50px;
  }
  input.input-mail-center-top {
    height: 50px;
  }
  textarea.form-control {
    height: 150px !important;
  }
  div.row-flex-spaced {
    padding-left: 64px;
    padding-right: 64px;
  }
  div.box {
    max-width: 250px;
    max-height: 100%;
  }
  /* Events In coming */
  div.card-body-event-coming {
    height: 280px;
  }
  p.text-event-coming {
    font-size: 20px;
    line-height: 0.6;
  }
  .react-html-parser p {
    max-width: 90%;
    font-size: 18px;
    line-height: 1.2;
    letter-spacing: 0.15rem;
  }
  div.cta {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  a.see-more {
    font-size: 13px;
  }
  a.see-more:hover {
    font-size: 13px;
  }

  /* Events */
  div.card-event {
    max-height: 300px;
  }
  p.text-event {
    font-size: 17px;
    line-height: 0.4;
  }
  p.text-event p {
    font-size: 15px;
  }
  div.card-event-past {
    height: 700px;
  }
  div.card-body-height {
    height: 400px;
  }
  div.card-title-event-past {
    height: 50px;
  }
  p.text-event-past {
    height: 120px;
  }
  p.text-event-past p {
    font-size: 17px;
  }
  div.text-single-event {
    padding-left: 100px;
  }
  div.text-single-event p {
    width: 70%;
    font-size: 22px;
  }
  .image-gallery {
    width: 100%;
  }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image {
    width: 65vh !important;
    height: 50vh !important;
  }

  /* Arts */
  div.text-single-art p {
    font-size: 16px;
  }
  div.card-galery {
    max-height: 210px;
    max-width: 100%;
  }
  .card-galery a {
    max-height: 210px;
    max-width: 100%;
  }
  .card-galery h3 {
    font-size: 24px;
  }
  div.card-galery-vertical {
    height: 210px;
    width: 150px;
  }
  .card-galery-vertical h3 {
    font-size: 24px;
  }
  div.card-art {
    height: 240px;
  }
  .card-art a {
    max-width: 100%;
  }
  /* Contact */
  p.contact-text {
    font-size: 18px;
  }
  a.mail {
    font-size: 16px;
  }
  a.mail:hover {
    font-size: 16px;
  }
  button.submit-button {
    padding: 6px 16px;
    font-size: 12px;
  }
  button.submit-button:hover {
    padding: 6px 16px;
    font-size: 12px;
  }
}

@media screen and (max-width: 1400px) {
  .App h1 {
    font-size: 64px;
  }
  h1.first-title {
    font-size: 64px;
  }
  h2.title-centered {
    font-size: 40px;
  }
  .App h3 {
    font-size: 24px;
  }
  h3.title-black {
    font-size: 24px;
  }
  h3.title-art {
    font-size: 30px;
  }
  h3.title-centered {
    font-size: 30px;
  }
  .App h4 {
    font-size: 32px;
  }
  h4.type {
    font-size: 32px;
  }
  .App p {
    font-size: 11px;
  }
  .App b {
    font-size: 11px;
  }
  input.input-mail-top {
    height: 40px;
  }
  input.input-mail-top-left {
    height: 40px;
  }
  input.input-mail-center {
    height: 40px;
  }
  input.input-mail-center-top {
    height: 40px;
  }
  textarea.form-control {
    height: 140px !important;
  }
  div.row-flex-spaced {
    padding-left: 32px;
    padding-right: 32px;
  }
  div.box {
    max-width: 200px;
    max-height: 100%;
  }
  /* Events In coming */
  div.card-body-event-coming {
    height: 250px;
  }
  p.text-event-coming {
    font-size: 18px;
    line-height: 0.4;
  }
  .react-html-parser p {
    max-width: 90%;
    font-size: 16px;
    line-height: 1.2;
    letter-spacing: 0.12rem;
  }
  div.cta {
    padding-bottom: 16px;
    padding-top: 16px;
  }
  a.see-more {
    font-size: 13px;
  }
  a.see-more:hover {
    font-size: 13px;
  }

  /* Events */
  div.card-event {
    max-height: 300px;
  }
  p.text-event {
    font-size: 15px;
    line-height: 0.4;
  }
  p.text-event p {
    font-size: 13px;
  }
  div.card-event-past {
    height: 700px;
  }
  div.card-body-height {
    height: 350px;
  }
  div.event-img {
    max-width: 100%;
    max-height: 150px;
  }
  div.card-title-event-past {
    height: 50px;
  }
  p.text-event-past {
    height: 110px;
  }
  p.text-event-past p {
    font-size: 15px;
  }
  div.text-single-event {
    padding-left: 100px;
  }
  div.text-single-event p {
    width: 70%;
    font-size: 17px;
  }
  .image-gallery {
    width: 100%;
  }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image {
    width: 60vh !important;
    height: 45vh !important;
  }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    width: 50px !important;
  }

  /* Arts */
  div.text-single-art p {
    font-size: 15px;
  }
  div.card-galery {
    max-height: 160px;
    max-width: 100%;
    margin: 0 8px;
  }
  .card-galery a {
    max-height: 160px;
    max-width: 100%;
  }
  .card-galery h3 {
    font-size: 20px;
  }
  div.card-galery-vertical {
    height: 160px;
    width: 120px;
    margin: 0 8px;
    margin-left: 0;
  }
  .card-galery-vertical h3 {
    font-size: 20px;
  }
  div.card-art {
    height: 200px;
  }
  .card-art a {
    max-width: 100%;
  }
  /* Contact */
  p.contact-text {
    font-size: 16px;
  }
  a.mail {
    font-size: 14px;
  }
  a.mail:hover {
    font-size: 14px;
  }
  button.submit-button {
    padding: 6px 16px;
    font-size: 10px;
  }
  button.submit-button:hover {
    padding: 6px 16px;
    font-size: 10px;
  }
}

/* Tablette */
@media screen and (min-width: 771px) and (max-width: 1399px) {
  .App h1 {
    font-size: 50px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  h1.first-title {
    font-size: 64px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  h2.title-centered {
    font-size: 32px;
  }
  .App h3 {
    font-size: 20px;
    padding-bottom: 0;
  }
  h3.title-black {
    font-size: 18px;
  }
  h3.title-art {
    font-size: 24px;
  }
  h3.title-centered {
    font-size: 24px;
  }
  .App h4 {
    font-size: 28px;
  }
  h4.type {
    font-size: 28px;
  }
  .App p {
    font-size: 10px;
  }
  .App b {
    font-size: 10px;
  }
  input.input-mail-top {
    height: 20px;
    font-size: 12px;
  }
  input.input-mail-top-left {
    height: 20px;
    font-size: 12px;
  }
  input.input-mail-center {
    height: 20px;
    font-size: 12px;
  }
  input.input-mail-center-top {
    height: 20px;
    font-size: 12px;
  }
  textarea.form-control {
    font-size: 12px;
    height: 100px !important;
  }
  div.row-flex-spaced {
    padding-left: 16px;
    padding-right: 16px;
  }
  div.box {
    max-width: 180px;
    max-height: 100%;
  }
  /* Events In coming */
  div.card-body-event-coming {
    height: 200px;
  }
  p.text-event-coming {
    font-size: 14px;
  }
  .react-html-parser p {
    font-size: 12px;
  }
  div.cta {
    padding-bottom: 16px;
    padding-top: 0px;
  }
  a.see-more {
    font-size: 11px;
  }
  a.see-more:hover {
    font-size: 11px;
  }

  /* Events */
  div.card-event {
    max-height: 190px;
    margin-bottom: 16px;
    width: 80%;
  }
  p.text-event {
    font-size: 12px;
    line-height: 0.1;
  }
  p.text-event p {
    font-size: 11px;
    margin: 0;
  }
  div.card-event-past {
    height: 500px;
    padding: 0;
  }
  div.card-body-height {
    height: 200px;
  }
  div.event-img {
    max-width: 100%;
    max-height: 100px;
  }
  div.card-title-event-past {
    height: 50px;
  }
  p.text-event-past {
    height: 100px;
  }
  p.text-event-past p {
    font-size: 12px;
  }
  div.text-single-event {
    padding-left: 100px;
  }
  div.text-single-event p {
    width: 70%;
    font-size: 14px;
  }
  .image-gallery {
    width: 100%;
  }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image {
    width: 50vh !important;
    height: 40vh !important;
  }
  .image-gallery-left-nav .image-gallery-svg,
  .image-gallery-right-nav .image-gallery-svg {
    width: 30px !important;
  }
  /* Arts */
  div.text-single-art p {
    font-size: 13px;
  }
  div.card-galery {
    max-height: 130px;
    max-width: 100%;
  }
  .card-galery a {
    max-height: 130px;
    max-width: 100%;
  }
  .card-galery h3 {
    font-size: 16px;
  }
  div.card-galery-vertical {
    height: 130px;
    width: 90px;
  }
  .card-galery-vertical h3 {
    font-size: 16px;
  }
  div.card-art {
    height: 150px;
  }
  /* Contact */
  p.contact-text {
    font-size: 13px;
  }
  a.mail {
    font-size: 12px;
  }
  a.mail:hover {
    font-size: 12px;
  }
  button.submit-button {
    padding: 6px 8px;
    margin-top: 16px;
    font-size: 9px;
  }
  button.submit-button:hover {
    padding: 6px 16px;
    font-size: 10px;
  }

  /* Footer */
  p.footerText {
    font-size: 7px;
  }

  a.link {
    font-size: 7px;
  }
}

@media screen and (max-width: 770px) {
  .App h1 {
    font-size: 40px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  h1.first-title {
    font-size: 48px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
  h1.title-centered-no-margin-top {
    font-size: 30px;
  }
  h1.logo {
    margin-top: 0px;
    margin-bottom: 8px;
  }
  h2.title-centered {
    font-size: 34px;
  }
  .App h3 {
    font-size: 18px;
  }
  h3.title-black {
    font-size: 16px;
    padding-left: 0;
  }
  h3.title-art {
    font-size: 30px;
  }
  h3.title-centered {
    font-size: 24px;
  }
  .App h4 {
    font-size: 30px;
  }
  h4.type {
    font-size: 30px;
    margin-top: 32px;
  }
  h4.title-centered {
    margin-top: 32px;
  }
  .App p {
    font-size: 12px;
    font-family: 'Quicksand', sans-serif;
    text-transform: uppercase;
  }

  .App b {
    font-size: 12px;
  }
  div.margin-up {
    justify-content: flex-start;
  }
  input.input-mail-top {
    height: 40px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 12px;
  }
  input.input-mail-top-left {
    height: 40px;
    border: 1px solid black;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 12px;
  }
  input.input-mail-center {
    height: 40px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 12px;
  }
  input.input-mail-center-top {
    height: 40px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 12px;
  }
  textarea.form-control {
    height: 150px !important;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 12px;
  }
  div.box {
    max-width: 200px;
  }
  /* Events In coming */
  div.card-title {
    height: 50px;
  }
  div.card-body-event-coming {
    height: 200px;
    padding: 0;
  }
  p.text-event-coming {
    font-size: 12px;
    width: 100%;
    margin-bottom: 0 !important;
  }
  .react-html-parser p {
    font-size: 11px;
  }
  div.cta {
    padding-bottom: 16px;
    padding-top: 0px;
  }
  a.event-link {
    font-size: 11px;
    border: 2px solid #faf7f7;
  }
  div.cta-see-more {
    max-height: 28px;
  }
  a.see-more {
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  a.see-more:hover {
    font-size: 10px;
  }

  /* Events */
  div.card-event {
    max-height: 200px;
    margin-bottom: 16px;
    width: 90%;
  }
  div.card-body {
    padding: 8px 16px;
  }
  p.text-event {
    font-size: 12px;
    line-height: 1.4;
    margin: 0;
  }
  p.text-event p {
    font-size: 11px;
    margin: 0;
  }
  div.card-event-past {
    border: 1px solid black !important;
    min-width: 90%;
    max-width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
    height: 500px;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  div.card-body-event-past {
    padding: 0px;
  }
  div.event-img {
    max-width: 300px;
    height: 150px;
    padding: 0px 16px 16px 16px;
  }
  div.card-title-event-past {
    height: 50px;
  }
  p.text-event-past {
    height: 50px;
  }
  p.text-event-past p {
    font-size: 12px;
    line-height: 1.4;
    margin-top: 8px;
  }
  div.text-single-event {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    padding-left: 0px;
    height: 100px;
  }
  div.text-single-event p {
    font-size: 14px;
    line-height: 1.2;
  }
  .image-gallery {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image {
    width: 100% !important;
    height: 35vh !important;
    background: none;
  }
  .image-gallery-fullscreen-button {
    display: none;
  }
  /* Arts */
  div.text-single-art {
    line-height: 1.2;
    padding: 0px;
  }
  div.text-single-art p {
    font-size: 12px;
  }
  div.text-single-art b {
    font-size: 10px;
    margin-top: 12px;
    text-align: center;
  }
  div.card-galery {
    height: 200px;
    max-width: 100%;
    margin-bottom: 32px !important;
  }
  .card-galery a {
    height: 200px;
    max-width: 80%;
    border: 1px solid black;
  }
  .card-galery h3 {
    top: 48%;
  }
  div.card-galery-vertical {
    height: 200px;
    width: 100%;
    margin: 16px 0px;
    margin-bottom: 16px !important;
  }
  .card-galery-vertical h3 {
    top: 48%;
    font-size: 16px;
  }
  div.card-art {
    height: 150px;
    padding: 16px;
    margin-bottom: 32px !important;
  }
  .card-art a {
    max-width: 100%;
    justify-content: center;
    border: none;
  }
  .card-art a:hover {
    border: none;
  }
  .card-art img {
    border: 1px solid black;
  }

  /* Contact */
  p.contact-text {
    font-size: 14px;
  }
  a.mail {
    font-size: 13px;
    text-decoration: underline;
  }
  a.mail:hover {
    font-size: 13px;
  }
  button.submit-button {
    margin-top: 16px;
    padding: 8px 8px;
    font-size: 10px;
  }
  button.submit-button:hover {
    margin-top: 16px;
    padding: 8px 8px;
    font-size: 10px;
  }

  /* error */
  p.error-text {
    font-size: 18px;
    margin-top: 32px;
  }
  a.back {
    width: 150px;
  }
  p.p-link {
    font-size: 10px;
  }
}

/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 420px) {
  .App h1 {
    font-size: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  h1.logo {
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 8px;
  }
  h1.first-title {
    font-size: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  h1.title-centered-no-margin-top {
    font-size: 24px;
  }

  h2.title-centered {
    font-size: 28px;
  }
  .App h3 {
    font-size: 16px;
  }
  h3.title-black {
    font-size: 14px;
    padding-left: 0;
  }
  h3.title-art {
    font-size: 18px;
    margin: 0;
  }
  h3.title-centered {
    font-size: 20px;
  }
  .App h4 {
    font-size: 24px;
    margin: 8px 0px;
  }
  h4.type {
    font-size: 24px;
    margin-top: 8px;
  }
  h4.title-centered {
    margin-top: 32px;
    margin-bottom: 0;
  }
  .App p {
    font-size: 10px;
    font-family: 'Quicksand', sans-serif;
    text-transform: unset;
    margin-bottom: 4px;
    line-height: 1.3;
  }
  .App b {
    font-size: 10px;
    font-weight: 500;
    text-transform: unset;
  }
  div.margin-up {
    justify-content: flex-start;
    margin: 0;
  }
  div.margin-bot {
    margin-bottom: 0;
  }
  input.input-mail-top {
    height: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  input.input-mail-top-left {
    height: 30px;
    border: 1px solid black;
    margin-top: 8px;
    margin-bottom: 8px;
    font-size: 10px;
    padding: 6px;
  }
  input.input-mail-center {
    height: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  input.input-mail-center-top {
    height: 30px;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  textarea.form-control {
    height: 100px !important;
    margin-top: 8px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  div.box {
    max-width: 150px;
  }
  /* Events In coming */
  div.card-title {
    height: 50px;
  }
  div.card-body-event-coming {
    height: 180px;
    padding: 0;
  }
  p.text-event-coming {
    font-size: 11px;
    width: 100%;
    margin-bottom: 0 !important;
  }
  .react-html-parser p {
    font-size: 10px;
  }
  div.cta {
    padding-bottom: 16px;
    padding-top: 0px;
  }
  a.event-link {
    font-size: 9px;
    border: 2px solid #faf7f7;
  }
  div.cta-see-more {
    max-height: 28px;
  }
  a.see-more {
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  a.see-more:hover {
    font-size: 10px;
  }

  /* Events */
  div.card-event {
    max-height: 160px;
    margin-bottom: 16px;
    width: 95%;
  }
  div.card-body {
    padding: 8px 16px;
  }
  p.text-event {
    font-size: 11px;
    line-height: 1.2;
    margin: 0;
  }
  p.text-event p {
    font-size: 10px;
    margin: 0;
  }
  div.card-event-past {
    border: 1px solid black !important;
    min-width: 90%;
    max-width: 90%;
    margin-left: auto !important;
    margin-right: auto !important;
    height: 400px;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  div.card-body-event-past {
    padding: 0px;
  }
  div.event-img {
    max-width: 200px;
    height: 110px;
    padding: 0px 16px 16px 16px;
  }
  div.card-title-event-past {
    height: 50px;
  }
  p.text-event-past {
    height: 50px;
    width: 80%;
  }
  p.text-event-past p {
    font-size: 10px;
    line-height: 1.4;
    margin-top: 8px;
  }
  div.text-single-event {
    display: flex;
    justify-content: center;
    margin-top: 8px;
    padding-left: 0px;
    height: 50px;
  }
  div.text-single-event p {
    font-size: 11px;
    line-height: 1.2;
  }
  .image-gallery {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image {
    width: 100% !important;
    height: 45vh !important;
    background: none;
  }
  .image-gallery-fullscreen-button {
    display: none;
  }
  .image-gallery-left-nav {
    display: none;
  }
  .image-gallery-right-nav {
    display: none;
  }
  .image-gallery-thumbnails-wrapper.left
    .image-gallery-thumbnails
    .image-gallery-thumbnail,
  .image-gallery-thumbnails-wrapper.right
    .image-gallery-thumbnails
    .image-gallery-thumbnail {
    max-width: 50px;
  }
  /* Arts */
  div.text-single-art {
    line-height: 1.2;
    padding: 0px;
  }
  div.text-single-art p {
    font-size: 10px;
  }
  div.text-single-art b {
    font-size: 9px;
    margin-top: 8px;
    font-weight: 500;
  }
  div.card-galery {
    height: 100px;
    max-width: 100%;
    margin-bottom: 32px !important;
  }
  .card-galery a {
    height: 100px;
    max-width: 80%;
    border: 1px solid black;
  }
  .card-galery h3 {
    top: 42%;
    font-size: 12px;
  }
  div.card-galery-vertical {
    height: 100px;
    width: 90%;
    margin:0;
    justify-content: center;
  }
  .card-galery-vertical h3 {
    font-size: 12px;
  }
  div.card-art {
    height: 100px;
    padding: 8px;
    margin-bottom: 16px !important;
  }

  /* Contact */
  p.contact-text {
    font-size: 11px;
  }
  a.mail {
    font-size: 10px;
  }
  a.mail:hover {
    font-size: 10px;
  }
  button.submit-button {
    margin-top: 8px;
    padding: 4px 8px;
    font-size: 10px;
  }
  button.submit-button:hover {
    margin-top: 8px;
    padding: 4px 8px;
    font-size: 10px;
  }

  /* error */
  p.error-text {
    font-size: 14px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  a.back {
    width: 150px;
    margin-top: 0;
  }
  p.p-link {
    font-size: 10px;
  }
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  .App h1 {
    font-size: 28px;
    margin-top: 8px;
    margin-bottom: 8px;
    text-align: center;
  }
  h1.logo {
    font-size: 24px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  h1.first-title {
    font-size: 30px;
    margin-top: 0px;
    margin-bottom: 0px;
  }
  h1.title-centered-no-margin-top {
    font-size: 22px;
  }
  h1.title-centered-no-margin-bottom {
    letter-spacing: 5px;
  }
  h2.title-centered {
    font-size: 24px;
  }
  .App h3 {
    font-size: 16px;
    padding: 8px 0px;
    margin: 0;
  }
  h3.title-black {
    font-size: 13px;
    padding-left: 0;
  }
  h3.title-art {
    font-size: 18px;
    margin: 0;
  }
  h3.title-centered {
    font-size: 20px;
    text-align: left;
    padding-left: 16px;
  }
  .App h4 {
    font-size: 22px;
    margin: 0px 0px;
    text-align: center;
  }
  h4.type {
    font-size: 22px;
    margin-top: 16px;
  }
  h4.title-centered {
    margin-top: 16px;
    margin-bottom: 0;
  }
  .App p {
    font-size: 10px;
    text-transform: unset;
    margin-bottom: 4px;
    line-height: 1.4;
  }
  .App b {
    font-size: 10px;
    font-weight: 500;
    text-transform: unset;
  }
  div.margin-up {
    justify-content: flex-start;
    margin-top: 16px;
  }
  div.margin-bot {
    margin-bottom: 0;
  }
  input.input-mail-top {
    height: 30px;
    margin-top: 0px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  input.input-mail-top-left {
    height: 30px;
    border: 1px solid black;
    margin-top: 0px;
    margin-bottom: 8px;
    font-size: 10px;
    padding: 6px;
  }
  input.input-mail-center {
    height: 30px;
    margin-top: 0px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  input.input-mail-center-top {
    height: 30px;
    margin-top: 0px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  textarea.form-control {
    height: 100px !important;
    margin-top: 0px;
    margin-bottom: 8px;
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  div.box {
    max-width: 100%;
    padding: 8px 16px;
  }
  div.row-flex-spaced {
    display: flex;
    flex-direction: column;
  }
  /* Events In coming */
  div.card-title {
    height: 35px;
    margin: 0;
  }
  div.card-body-event-coming {
    height: 170px;
    padding: 0;
  }
  p.text-event-coming {
    font-size: 12px;
    width: 100%;
    margin-bottom: 0;
    padding: 0;
  }
  .react-html-parser p {
    font-size: 10px;
    line-height: 1.2;
    margin-bottom: 0;
    padding: 8px 0px;
  }
  div.cta {
    padding-bottom: 16px;
    padding-top: 0px;
  }
  a.event-link {
    font-size: 10px;
    border: 2px solid #faf7f7;
  }
  div.cta-see-more {
    max-height: auto;
  }
  a.see-more {
    border: 1px solid black;
    font-size: 10px;
    padding: 6px;
  }
  a.see-more:hover {
    font-size: 10px;
  }

  /* Events */
  div.card-event {
    max-height: 140px;
    margin-bottom: 16px;
    width: 95%;
  }
  div.card-body {
    padding: 4px 8px;
  }
  p.text-event {
    font-size: 11px;
    line-height: 1.2;
    margin: 0;
  }
  p.text-event p {
    font-size: 10px;
    margin: 0;
  }
  div.card-event-past {
    border: 1px solid black !important;
    min-width: 95%;
    max-width: 95%;
    margin-left: auto !important;
    margin-right: auto !important;
    height: 350px;
    margin-top: 16px !important;
    margin-bottom: 16px !important;
  }
  div.card-body-event-past {
    padding: 0px;
  }
  div.event-img {
    max-width: 150px;
    height: 100px;
    padding: 0px 16px 16px 16px;
  }
  div.card-title-event-past {
    height: 50px;
  }
  p.text-event-past {
    height: auto;
    width: 100%;
  }
  p.text-event-past p {
    font-size: 11px;
    line-height: 1.4;
    margin-top: 8px;
    margin-bottom: 8px;
    width: 100%;
  }
  div.text-single-event {
    display: flex;
    justify-content: center;
    margin-top: 16px;
    height: 50px;
    width: 100%;
  }
  div.text-single-event p {
    font-size: 12px;
    line-height: 1.2;
    width: 80%;
  }
  .image-gallery {
    width: 100%;
    display: flex;
    justify-content: center;
    align-self: center;
  }
  .image-gallery-content.left .image-gallery-slide .image-gallery-image {
    width: 100% !important;
    height: 45vh !important;
    background: none;
  }
  .image-gallery-fullscreen-button {
    display: none;
  }
  .image-gallery-left-nav {
    display: none;
  }
  .image-gallery-right-nav {
    display: none;
  }
  .image-gallery-thumbnails-wrapper.left
    .image-gallery-thumbnails
    .image-gallery-thumbnail,
  .image-gallery-thumbnails-wrapper.right
    .image-gallery-thumbnails
    .image-gallery-thumbnail {
    max-width: 50px;
  }
  /* Arts */
  div.text-single-art {
    line-height: 1.3;
    padding: 0px;
  }
  div.text-single-art p {
    font-size: 11px;
  }
  div.text-single-art b {
    font-size: 10px;
    margin-top: 8px;
    font-weight: 500;
  }
  div.card-galery {
    height: 90px;
    max-width: 100%;
    margin-bottom: 32px !important;
  }
  .card-galery a {
    height: 90px;
    max-width: 80%;
    border: 1px solid black;
  }
  .card-galery h3 {
    top: 40%;
    font-size: 12px;
  }
  div.card-galery-vertical {
    height: 90px;
    width: 100%;
    margin: 0;
  }
  .card-galery-vertical h3 {
    top: 40%;
    font-size: 12px;
  }
  div.card-art {
    height: 150px;
    padding: 8px;
    margin-bottom: 32px !important;
    min-width: 45%;
    max-width: 45%;
    width: 100%;
  }

  /* Contact */
  p.contact-text {
    font-size: 12px;
  }
  a.mail {
    font-size: 11px;
    text-transform: unset;
  }
  a.mail:hover {
    font-size: 10px;
    text-transform: unset;
  }
  button.submit-button {
    margin-top: 8px;
    padding: 4px 8px;
    font-size: 10px;
  }
  button.submit-button:hover {
    margin-top: 8px;
    padding: 4px 8px;
    font-size: 10px;
  }

  /* error */
  p.error-text {
    font-size: 14px;
    margin-top: 32px;
    margin-bottom: 32px;
  }
  a.back {
    width: 150px;
    margin-top: 0;
  }
  p.p-link {
    font-size: 10px;
  }
  /* NavBar */
  .dropdown-menu.show {
    position: absolute !important;
    max-width: 100px !important;
    min-width: unset !important;
    padding: 0;
    margin-top: 16px;
  }
  .dropdown-menu a {
    font-size: 9px;
  }
}

/*Boostrap */
.card-group .card {
  flex: unset !important;
}
