div.footerStyle {
  background-color: #faf7f7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 32px;
  height:auto;
}
div.textAlign {
  text-align: center;
}
div.infos {
  padding-top: 32px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
img.logo {
  width: 40px;
  align-self: center;
}
a.email {
  color: black;
  text-decoration: none;
  font-family: 'Quicksand';
}
a.email:hover {
  color: #808080;
}
div.team {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

/*Laptop*/
@media screen and (min-width: 1400px) and (max-width: 1900px) {
  a.email {
    font-size: 12px;
  }
}

@media screen and (max-width: 1400px) {
  a.email {
    font-size: 12px;
  }
}

/* Tablette */
@media screen and (min-width: 771px) and (max-width: 1050px) {
  a.email {
    font-size: 12px;
  }
}

@media screen and (max-width: 770px) {
}


/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 400px) {
  div.footerStyle {
    height: auto;
  }
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  div.footerStyle {
    height: auto;
  }
}