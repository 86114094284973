h4.title {
  margin: 64px 0;
}
.eventSinglePage {
  padding-top: 2vh;
  width: 100%;
  padding-left: 400px;
  display: flex;
  flex-direction: column;
  background-color: #808080;
  height: calc(100vh);
}
.carousselEvent {
  width: 100%;
}
.carousselDivEvent {
  max-height: 400px;
}
.carousselDivEvent img {
  width: 100%;
}

@media screen and (max-width: 770px) {
  h4.title {
    margin: 32px 0;
  }
  .eventSinglePage {
    padding-left: 250px;
    height: auto;
  }
  .carousselEvent {
    width: 100%;
  }
  .carousselDivEvent {
    max-height: 200px;
  }
  .carousselDivEvent img {
    width: 100%;
  }
}


/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 400px) {

}
@media screen and (min-width: 350px) and (max-width: 420px) {
  .eventSinglePage {
    padding-top: 120px;
    padding-left: 0px;
    height: auto;
    padding-bottom:8px;
  }
}