div.errorStyle {
  padding-top: 2vh;
  width: 100%;
  min-height: 600px;
  padding-left: 400px;
  display: flex;
  flex-direction: column;
  background-color: #faf7f7;
}

@media screen and (max-width: 770px) {
  div.errorStyle {
    height: auto;
    padding-left: 250px;
  }
}

/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 400px) {
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  div.errorStyle {
    padding-top: 120px;
    width: 100%;
    height: auto;
    min-height: 60vh;
    padding-left: 0px;
    display: flex;
    flex-direction: column;
    background-color: #faf7f7;
  }
}
