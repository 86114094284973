.artSinglePage {
  padding-top: 2vh;
  width: 100%;
  padding-left: 400px;
  background-color: #808080;
  height: calc(100vh);
}
.imageDiv {
  min-width: unset;
  max-width: 500px;
  max-height: 400px;
  padding: 0;
}
.imageDiv img {
  border: 2px solid black;
  max-width: 100%;
  max-height: 100%;
}

/*Laptop*/
@media screen and (min-width: 1400px) and (max-width: 1900px) {
  .imageDiv {
    max-width: 400px;
    max-height: 300px;
  }
}

@media screen and (max-width: 1400px) {
  .imageDiv {
    max-width: 250px;
    max-height: 300px;
  }
}

/* Tablette */
@media screen and (min-width: 771px) and (max-width: 1050px) {
  .imageDiv {
    max-height: 220px;
  }
}

@media screen and (max-width: 770px) {
  .artSinglePage {
    padding-left: 250px;
    height: auto;
  }
  .imageDiv {
    max-width: 100%;
    max-height: 400px;
    padding: 0;
  }
}
/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 400px) {
  .artSinglePage {
    padding-left: 200px;
    height: 100vh;
  }
  .imageDiv {
    max-width: 100%;
    max-height: 200px;
    padding: 0;
  }
  .imageDiv img {
    border: 1px solid black;
    max-width: 100%;
    max-height: 100%;
  }
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  .artSinglePage {
    padding-top: 120px;
    padding-left: 16px;
    padding-right: 16px;
    height: auto;
    padding-bottom: 16px;
  }
  .imageDiv {
    max-width: 100%;
    max-height: 200px;
    padding: 0;
  }
  .imageDiv img {
    border: 1px solid black;
    max-width: 100%;
    max-height: 100%;
  }
}
