.galeryStyle {
  padding-top: 2vh;
  width: 100%;
  height: 100vh;
  padding-left: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #808080;
}
.cardGroup {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: center;
  margin: 32px 0;
  background-color: #808080;
}

.cardSizeType {
  max-height: 450px;
  max-width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  justify-self: center;
  margin-bottom: 64px;
}
.cardSizeType a {
  max-height: 450px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: none;
}
.cardSizeType a:hover {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid #808080;
}
.h3Card {
  position: absolute;
  left: 42%;
  top: 45%;
}
.imageType {
  max-width: 100%;
  height: 100%;
  align-self: center;
}

/*Laptop*/
@media screen and (min-width: 1400px) and (max-width: 1900px) {

}

@media screen and (max-width: 1400px) {

}

/* Tablette */
@media screen and (min-width: 771px) and (max-width: 1050px) {

}


@media screen and (max-width: 770px) {
  .galeryStyle {
    padding-left: 250px;
  }
  .cardGroup {
    display: flex;
    flex-direction: column !important;
    margin: 32px 0;
  }
  
  .cardSizeType {
    max-height: 450px;
    max-width: 100%;
    border: none;
    display: flex;
    flex-direction: column;
    justify-self: center;
    margin-bottom: 64px;
  }
  .cardSizeType a {
    max-height: 450px;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    text-decoration: none;
    border: none;
  }
  .cardSizeType a:hover {
    display: flex;
    flex-direction: column;
    text-decoration: none;
    border: 1px solid #808080;
  }
  .h3Card {
    position: absolute;
    left: 42%;
    top: 45%;
  }
  .imageType {
    max-width: 100%;
    height: 100%;
    align-self: center;
  }
  
}

/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 400px) {
  .galeryStyle {
    height: auto;
  }
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  .galeryStyle {
    height: auto;
    padding-left:0;
  }
}