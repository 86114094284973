@font-face {
  font-family: 'Quicksand';
  src: url('../fonts/quicksand/Quicksand-Regular.ttf');
}

ul li {
  min-width: 200px;
}
.navBar {
  position: fixed;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  color: black;
  font-size: 16px;
  min-width: 400px;
  height: 100%;
  background: transparent;
  font-family: "Quicksand"
}
.logo {
  list-style: none;
  width: 50px;
}
.firstItem a {
  width: 100px;
  margin-top: 128px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.firstItem a:hover {
  text-decoration: none;
}
.secondItem {
  list-style: none;
  margin-top: 16px;
}
.secondItem a {
  text-decoration: none;
  color: black;
  padding: 0;
  font-size: 16px;
}
.secondItem a:visited {
  text-decoration: none;
  color: black;
  padding: 0;
}
.secondItem a:hover {
  text-decoration: none;
  color: #808080;
  padding: 0;
}
.navItem {
  list-style: none;
  margin-top: 8px;
}
.navItem a {
  text-decoration: none;
  color: black;
  font-size: 16px;
}
.navItem a:visited {
  text-decoration: none;
  color: black;
}
.navItem a:hover {
  text-decoration: none;
  color: #808080;
}

/* dropdown Menu */
a.dropdownMenuStyle {
  transform: none;
}

.dropdownMenuNavLink {
  font-size: 14px;
  padding: 0;
}

/*Laptop*/
@media screen and (min-width: 1400px) and (max-width: 1900px) {
}

@media screen and (max-width: 1400px) {
  ul li {
    min-width: 200px;
  }
  .navBar {
    font-size: 14px;
  }
  .secondItem a {
    font-size: 14px;
  }
  .navItem {
    list-style: none;
    margin-top: 0;
  }
  .navItem a {
    font-size: 14px;
  }
  .dropdownMenuNavLink {
    font-size: 12px;
  }
}

/* Tablette */
@media screen and (min-width: 770px) and (max-width: 1050px) {
  .navBar {
    font-size: 14px;
  }
  .logo {
    width: 35px;
  }
  .firstItem a {
    margin-top: 54px;
  }
  .secondItem a {
    font-size: 13px;
  }
  .navItem a {
    font-size: 13px;
    margin-top: 0;
  }

  /* dropdown Menu */
  .dropdownMenuNavLink {
    font-size: 12px;
    padding: 0;
  }
}

@media screen and (max-width: 780px) {
  ul li {
    min-width: 100px;
  }
  .navBar {
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-transform: uppercase;
    color: black;
    font-size: 16px;
    height: 100%;
    background: transparent;
    min-width: 250px;
  }
  .logo {
    width: 30px;
  }
  .firstItem a {
    width: 100px;
    margin-top: 64px;
  }
  .secondItem {
    width: 100px;
    list-style: none;
  }
  .secondItem a {
    font-size: 13px;
  }
  .navItem {
    list-style: none;
    margin-top: 4px;
  }
  .navItem a {
    font-size: 13px;
  }

  .dropdownMenuNavLink {
    font-size: 10px;
  }
}

/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 400px) {
  .navBar {
    min-width: 200px;
  }
  .logo {
    width: 24px;
  }
  .firstItem a {
    width: 100px;
    margin-top: 16px;
  }
  .secondItem {
    width: 100px;
    list-style: none;
    margin-top: 8px;
  }
  .secondItem a {
    font-size: 11px;
  }
  .navItem {
    list-style: none;
    margin-top: 0px;
  }
  .navItem a {
    font-size: 11px;
  }

  .dropdownMenuNavLink {
    font-size: 10px;
  }
}

@media screen and (min-width: 350px) and (max-width: 420px) {
  ul li {
    min-width: unset;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .navBar {
    background-color: #faf7f7;
    position: fixed;
    z-index: 2;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: unset !important;
    width: 100%;
    height: 120px;
    padding-top: 32px;
    font-size: 11px;
  }
  .logo {
    list-style: none;
    width: 20px;
  }
  .firstItem a {
    width: 80%;
    margin-top: -16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .firstItem a:hover {
    text-decoration: none;
  }
  .secondItem {
    list-style: none;
    width: 100%;
    margin-top: 0px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 11px;
  }
  .secondItem a {
    text-decoration: none;
    color: black;
    padding: 0;
    font-size: 11px;
  }
  .secondItem a:visited {
    text-decoration: none;
    color: black;
    padding: 0;
  }
  .secondItem a:hover {
    text-decoration: none;
    color: #808080;
    padding: 0;
  }
  .navItem {
    list-style: none;
    margin-top: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .navItem a {
    text-decoration: none;
    color: black;
    font-size: 11px;
    width: 100%;
  }
  .navItem a:visited {
    text-decoration: none;
    color: black;
  }
  .navItem a:hover {
    text-decoration: none;
    color: #808080;
  }

  /* dropdown Menu */
  a.dropdownMenuStyle {
    transform: none;
    position: initial !important;
  }

  .dropdownMenuNavLink {
    font-size: 8px;
    padding: 0;
  }
}
