.contactStyle {
  padding-top: 2vh;
  height: 100vh;
  width: 100%;
  padding-left: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #faf7f7;
}

.cardGroup {
  background-color: #faf7f7 !important;
}

/* Tablette  */
@media screen and (max-width: 770px) {
  .contactStyle {
    padding-left: 250px;
    height: auto;
  }
  .formStyle {
    margin-top: 16px;
  }
}

/* Mobile */
@media screen and (max-width: 769px) and (max-height: 400px) {
  .contactStyle {
    padding-left: 200px;
    height: auto;
  }
  .formStyle {
    margin-top: 16px;
  }
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  .contactStyle {
    padding-top: 120px;
    height: auto;
    padding-left: 0px;
  }
}