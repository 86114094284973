.byCatStyle {
  padding-top: 2vh;
  width: 100%;
  padding-left: 400px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  background-color: #faf7f7;
  height: auto;
}
.cardGroup {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 32px 0;
  background-color: #faf7f7;
}

.cardSizeSubCat {
  height: 300px;
  justify-content: center;
  margin: 32px 32px;
}
.cardSizeSubCat a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 8px;
}
.cardSizeSubCat a:hover {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid #808080;
}
.imageSubCat {
  height: 300px;
  align-self: center;
}
.cardSizeCat {
  max-width: 500px;
  max-height: 500px;
  border: none;
  display: flex;
  flex-direction: column;
  justify-self: space-around;
  margin-bottom: 64px;
}
.cardSizeCat a {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  padding: 8px;
  border: none;
  max-width: 100%;
  max-height: 100%;
}
.cardSizeCat a:hover {
  display: flex;
  flex-direction: column;
  text-decoration: none;
  border: 1px solid #808080;
}
.imageCat {
  max-width: 100%;
  max-height: 100%;
  align-self: center;
}

/*Laptop*/
@media screen and (min-width: 1400px) and (max-width: 1900px) {
  .cardSizeSubCat {
    height: 250px;
  }
  .imageSubCat {
    height: 250px;
  }
}

@media screen and (max-width: 1400px) {
  .cardSizeSubCat {
    height: 200px;
    margin: 16px 32px;
  }
  .imageSubCat {
    height: 200px;
  }
}

/* Tablette */
@media screen and (min-width: 771px) and (max-width: 1050px) {
  .cardSizeSubCat {
    height: 150px;
    margin: 16px 16px;
  }
  .imageSubCat {
    height: 150px;
  }
}

@media screen and (max-width: 770px) {
  .byCatStyle {
    padding-left: 250px;
    height: auto;
    justify-content: flex-start;
  }
  .cardSizeSubCat {
    width: 100%;
    height: 150px;
    margin: 0;
  }
  .cardSizeSubCat a {
    width: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    text-decoration: none;
    height: 150px;
    border: 1px solid #808080;
    margin-bottom: 16px;
    margin-left: auto;
    margin-right: auto;
  }
  .cardSizeSubCat a:hover {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    height: 150px;
  }
  .imageSubCat {
    height: 100px;
  }
}

/*Mobile*/
@media screen and (max-width: 769px) and (max-height: 400px) {
  .byCatStyle {
    padding-left: 200px;
    height: auto;
  }
  .cardSizeSubCat {
    height: 100px;
    margin-bottom: 8px;
  }
  .cardSizeSubCat a {
    height: 100px;
  }
  .cardSizeSubCat a:hover {
    height: 100px;
  }
  .imageSubCat {
    height: 80px;
  }
}
@media screen and (min-width: 350px) and (max-width: 420px) {
  .byCatStyle {
    padding-top: 120px;
    padding-left: 0px;
    max-width: 100vw;
    height: auto;
  }
  .cardGroup {
    flex-wrap: wrap;
  }
  .cardSizeSubCat {
    height: 100px;
    margin-bottom: 8px;
  }
  .cardSizeSubCat a {
    height: 100px;
  }
  .cardSizeSubCat a:hover {
    height: 100px;
  }
  .imageSubCat {
    height: 80px;
  }
}